
import SubmitBtn from '@/components/Common/SubmitBtn.vue';
import TableComp from '@/components/Common/TableComp.vue';
import SearchGroup from '@/components/Common/SearchGroup.vue';
import TableOperaBtn from '@/components/Common/TableOperaBtn.vue';

import { GlobalVar } from '@/utils/global-var';
import { debounce } from '@/utils/function_utils';
import { Component, Vue } from 'vue-property-decorator';
import { ITablePage } from '@/interface/common';
import { NewsInfoDataParam } from '@/interface/newsManagement';
import { getIssueNewsListApi, getBmsCountryUnconditionalApi } from '@/api/news'
import { UserModule } from '@/store/modules/user'
@Component({
  name: 'newsInfoList',
  components: {
    SubmitBtn,
    TableComp,
    SearchGroup,
    TableOperaBtn,
  },
})
export default class extends Vue {
  // 获取新闻类型
  get getNewsTypeId() {
    if (UserModule.rolesDatas && UserModule.rolesDatas.length) {
      const data: any = UserModule.rolesDatas[0];
      return data.newsRoot;
    } else {
      return ''
    }
  }
  public tablePage: ITablePage = {
    showCount: GlobalVar.SHOWCOUNT,
    currentPage: 0,
    totalResult: 0,
    totalIssueItem: 0,
  }
  public reqFilterData: NewsInfoDataParam = {
    keyword: '',
    startTime: '',
    endTime: '',
    fkNewsTypeId: '',
    isRead: '',
    countryId: '',
    fkSchoolName: '',
  }
  public resTableData: any[] = [];
  // 发布时间范围
  public publishRangeData: any[] = [];
  // 国家数据
  public countryOptions: any[] = [];

  // 利用防抖避免连续多次点击发送多次请求
  public getTables = debounce(this.getTableDatas, 500);

  public activated() {
    if (!this.$route.query.type ||
    (this.$route.query.type && this.$route.query.type === 'return')) {
      this.getTables();
      this.getBmsCountryOptions();
    }
  }
  // 获取列表数据
  public getTableDatas() {
    this.reqFilterData.fkNewsTypeId = this.getNewsTypeId;
    const reqData = Object.assign({}, this.tablePage, { data: this.reqFilterData});
    getIssueNewsListApi(reqData).then((res: any) => {
      const data = res.data;
      if (data.page) {
        this.tablePage = data.page;
      }
      this.resTableData = data.datas;
    })
  }
  // 获取国家下拉
  public async getBmsCountryOptions() {
    const { data }: any = await getBmsCountryUnconditionalApi();
    this.countryOptions = data.datas || [];
  }
  // 发布时间范围
  public publishTimeChange(date: string[]) {
    this.reqFilterData.startTime = date[0];
    this.reqFilterData.endTime = date[1];
  }
  // 搜索
  public handleSearch() {
    this.tablePage.currentPage = 1;
    this.getTables();
  }
  // 重置
  public handleReset() {
    this.reqFilterData = {
      keyword: '',
      startTime: '',
      endTime: '',
      fkNewsTypeId: this.reqFilterData.fkNewsTypeId,
      isRead: '',
      countryId: '',
      fkSchoolName: '',
    };
    this.publishRangeData = [];
    this.tablePage = {
      showCount: GlobalVar.SHOWCOUNT,
      currentPage: 1,
      totalResult: 0,
      totalIssueItem: 0
    }
    this.getTables();
  }
  // 获取详情
  public handleDetail(row: any) {
    this.$router.push({
      name: 'newsInfoDetail',
      params: {id: row.id.toString()},
      query: {isRead: row.read}
      })
  }

  public handleSizeChange(pageSize: number) {
    this.tablePage.showCount = pageSize;
    this.getTables();
  }

  public handleCurrentChange(currentPage: number) {
    this.tablePage.currentPage = currentPage;
    this.getTables();
  }
}
